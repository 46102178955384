@import '../../styles/variables.module.scss';
button {
  background-color: transparent;
  padding: calc(0.75 * #{$unit}) calc(1.5 * #{$unit});
  transition: all 300ms;
}
.programare {
  margin-left: calc(4 * #{$unit});
  border: 1px solid $primaryColor;
  border-radius: calc(0.25 * #{$unit});
  &:hover {
    cursor: pointer;
    opacity: 0.75;
  }
  p {
    color: $primaryColor;
    font-size: calc(0.8125 * #{$unit});
    text-transform: uppercase;
    letter-spacing: 0.05rem;
  }
}
.btn-programare-solid {
  position: relative;
  background-color: $primaryColor;
  border: 1px solid $primaryColor;
  border-radius: calc(0.25 * #{$unit});
  width: 100%;
  &:hover {
    cursor: pointer;
    opacity: 0.75;
  }
  p {
    color: $primaryColorExtraLight;
    font-size: calc(0.8125 * #{$unit});
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.05rem;
  }
}

.btn-card-programare {
  width: 100%;
  position: relative;
  border: 1px solid $primaryColor;
  border-radius: calc(0.25 * #{$unit});
  padding: calc(0.5 * #{$unit}) calc(1 * #{$unit});
  // background-color: $primaryColor;
  &:hover {
    cursor: pointer;
    opacity: 0.75;
  }
  p {
    color: $primaryColor;
    text-transform: uppercase;
    font-size: 0.8125rem;
    line-height: 100%;
    letter-spacing: 0.05rem;
    font-weight: 500;
    @media (min-width: $xxl) {
      font-size: 0.8125rem;
    }
  }
}

.btn-programare-client {
  position: relative;
  width: 100%;
  max-width: 400px;
  background-color: $primaryColor;
  border: 1px solid $primaryColor;
  border-radius: calc(0.25 * #{$unit});
  padding: calc(1 * #{$unit}) calc(1.5 * #{$unit});
  @media (min-width: $xxl) {
    max-width: 480px;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.75;
  }
  p {
    color: $primaryColorExtraLight;
    font-size: calc(0.8125 * #{$unit});
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.05rem;
  }
}
