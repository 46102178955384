@import '../../../styles/variables.module.scss';
.benefits-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $unit;
  background-color: $primaryColorExtraLight;
  @media (min-width: $md) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media (min-width: $xxl) {
    gap: calc(1.25 * #{$unit});
    flex-direction: row;
    flex-wrap: wrap;
  }
}
