@import '../../../../styles/variables.module.scss';
.map-info {
  display: flex;
  align-items: center;
  gap: $unit;
  svg {
    width: calc(2.5 * #{$unit});
    height: auto;
  }
  p {
    font-size: 0.65rem;
    max-width: 22ch;
    line-height: 1.3;
    color: rgb(216, 216, 216);
    @media (min-width: $md) {
      max-width: 30ch;
    }
  }
}
