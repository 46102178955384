// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@import './layouts.scss';
@import './variables.module.scss';

p,
input,
textarea {
  font-family: 'Montserrat', sans-serif;
  line-height: 150%;
  color: $textDark;
}
h1,
h2,
h3,
.display1,
.display2,
.display3 {
  font-family: 'Montserrat', sans-serif;
  line-height: 105%;
  color: $textDark;
}

$fontSize: 1rem;

p {
  font-size: 1.125rem;
  font-weight: 400;
}
h1 {
  font-size: 2.188rem;
  font-weight: 700;
}
h2 {
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 120%;
}
h3 {
  font-size: 1.438rem;
  font-weight: 500;
  text-transform: capitalize;
}
select,
input {
  font-size: 1.125rem;
}
.display1 {
  font-size: 4.312rem;
  font-weight: 600;
}
.display2 {
  font-size: 3.438rem;
  font-weight: 600;
}
.display3 {
  font-size: 2.5rem;
  font-weight: 600;
}
.caption {
  font-size: 0.875rem;
  letter-spacing: calc(0.06 * #{$unit});
  text-transform: uppercase;
  color: $primaryColor;
  font-weight: 500;
}
.small {
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
}
.btn {
  font-size: 1.125rem;
  font-weight: bold;
  letter-spacing: calc(0.03 * #{$unit});
  text-transform: uppercase;
}
.navlink {
  font-size: 0.875rem;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
}
.card {
  font-size: 0.875rem;
}

.copyright {
  font-size: 0.5625rem;
  letter-spacing: 0.06rem;
  text-transform: uppercase;
  font-weight: 500;
}

@media (min-width: $xxl) {
  p {
    font-size: 1.25rem;
  }
  h1 {
    font-size: 2.438rem;
  }
  h2 {
    font-size: 1.938rem;
  }
  h3 {
    font-size: 1.562rem;
  }
  select,
  input {
    font-size: 1.25rem;
  }
  .display1 {
    font-size: 4.75rem;
  }
  .display2 {
    font-size: 3.812rem;
  }
  .display3 {
    font-size: 3.062rem;
  }
  .caption {
    font-size: 1rem;
  }
  .small {
    font-size: 0.812rem;
    letter-spacing: 0.0688rem;
  }
  .btn {
    font-size: 1.25rem;
  }
  .navlink {
    font-size: 1rem;
  }
  .copyright {
    font-size: 0.6875rem;
    letter-spacing: 0.06rem;
  }
  .card {
    font-size: 1rem;
  }
}
