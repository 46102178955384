@import '../../../styles/variables.module.scss';
.footer-links {
  width: 50%;
  flex-grow: 0;
  gap: $unit;
  position: relative;
  @media (min-width: $md) {
    flex-grow: 1;
    flex-basis: 0;
  }
  ul {
    position: relative;
    li {
      position: relative;
      margin-top: $unit;
      &:hover {
        cursor: pointer;
        opacity: 0.75;
      }
      &:first-child {
        margin-top: 0px;
      }
      p {
        text-transform: capitalize;
      }
    }
  }
}
