@import '../../styles/variables.module.scss';

img {
  object-fit: cover;
}

img.hero-image {
  inset: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  filter: brightness(40%);
}

img.masaj-relaxare,
img.masaj-terapeutic {
  width: 100%;
  height: 320px;
  @media (min-width: $md) {
    width: 100%;
    // height: 640px;
    height: 480px;
  }
  @media (min-width: $lg) {
    width: 50%;
    height: 75vh;
  }
  @media (min-width: $xl) {
    // height: 800px;
  }
  @media (min-width: $xxl) {
    // height: 960px;
  }
}

img.card-image {
  width: 100%;
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

img.netopia {
  height: auto;
  width: 100%;
  object-fit: contain;
  margin-bottom: auto;
}
