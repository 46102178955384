@import '../../styles/variables.module.scss';
.social-media-group {
  display: flex;
  align-items: center;
  gap: calc(1.5 * #{$unit});
  display: none;
  @media (min-width: $md) {
    display: flex;
  }
  svg {
    &:hover {
      cursor: pointer;
      opacity: 0.75;
      transition: all 300ms;
    }
  }
}
