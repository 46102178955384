@import '../../styles/variables.module.scss';
button.call-button {
  margin-left: $unit;
  background-color: transparent;
  padding: 0px;
  svg {
    width: calc(2.5 * #{$unit});
    height: auto;
  }
  @media (min-width: $md) {
    display: none;
  }
}
