@import '../../../styles/variables.module.scss';
.card-benefit {
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: calc(1.5 * #{$unit});
  gap: $unit;
  // border: 1px solid $border;
  border-radius: 8px;
  margin-left: $unit;
  margin-right: $unit;
  background: rgb(242, 249, 255);
  background: radial-gradient(
    circle,
    rgba(242, 249, 255, 0.8435749299719888) 0%,
    rgba(251, 251, 251, 1) 77%
  );
  @media (min-width: $md) {
    margin-left: 0px;
    margin-right: 0px;
    max-width: calc(50% - calc(0.5 * #{$unit}));
    height: 387px;
  }
  @media (min-width: $lg) {
    height: 306px;
  }
  @media (min-width: $xl) {
    height: 279px;
  }
  @media (min-width: $xxl) {
    max-width: calc(50% - calc(0.75 * #{$unit}));
    height: 264px;
  }
  img {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: calc(4 * #{$unit});
    height: calc(4 * #{$unit});
    object-fit: cover;
  }
  p {
    text-align: center;
  }
  p.benefit-header {
    font-weight: 600;
    text-transform: capitalize;
  }
  p.benefit-copy {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
