@import '../src//styles/reset.scss';
@import '../src/styles/typography.scss';
@import '../src/styles/layouts.scss';
@import '../src/styles/colors.scss';

#root {
  position: relative;
  width: 100%;
  height: 100vh; /* fallback for Js load */
  height: var(--doc-height);
}

section {
  &:first-child {
    margin-top: 0px;
  }
  &:last-child {
    margin-bottom: calc(4 * #{$unit});
  }
  margin-top: calc(4 * #{$unit});
  @media (min-width: $md) {
    margin-top: calc(4 * #{$unit});
  }
  @media (min-width: $xxl) {
    margin-top: calc(6 * #{$unit});
  }
}

.App {
  width: 100%;
  height: 100%;
  background-color: $primaryColorExtraLight;
  position: relative;
  overflow-y: auto;
}

.padding {
  padding: $unit;
  @media (min-width: $md) {
    padding: $unit calc(1.5 * #{$unit});
  }
  @media (min-width: $xxl) {
    padding: calc(2 * #{$unit}) calc(4 * #{$unit});
  }
}

.container-fluid {
  position: relative;
  width: 100%;
}

.container {
  position: relative;
  width: 100%;
  @media (min-width: $md) {
    width: 576px;
  }
  @media (min-width: $lg) {
    width: 768px;
  }
  @media (min-width: $xl) {
    width: 992px;
  }
  @media (min-width: $xxl) {
    width: 1200px;
  }
}

.row {
  position: relative;
  width: 100%;
  display: flex;
}

.row-reverse {
  flex-direction: row-reverse;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  @media (min-width: $md) {
    width: auto;
    display: grid;
    grid-template-columns: repeat(8, 80px);
  }
  @media (min-width: $xxl) {
    grid-template-columns: repeat(12, 80px);
    gap: 20px;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.m-auto {
  margin: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.wrapper {
  width: 100%;
  min-height: 100vh;
}

.h100 {
  height: 100%;
}

.select-atom {
  position: relative;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: $unit;
  font-family: 'Montserrat', sans-serif;
  @media (min-width: $md) {
    max-width: 400px;
  }
  @media (min-width: $xxl) {
    max-width: 480px;
  }
  p {
    // font-size: 0.875rem;
    font-weight: 600;
  }
  label {
    width: 100%;
    color: $textDark;
    padding: calc(0.5 * #{$unit}) 0px;
    text-transform: capitalize;
    p {
      font-size: 0.875rem;
      font-weight: 600;
    }
  }
  select {
    width: 100%;
    padding: calc(0.5 * #{$unit});
    color: $textDark;
    // font-size: 0.875rem;
    text-transform: capitalize;
    border: 1px solid $border;
    border-radius: calc(0.25 * #{$unit});
    font-family: 'Montserrat', sans-serif;
    background-color: $primaryColorExtraLight;
    &::placeholder {
      // font-size: 0.875rem;
    }
    &:focus {
      outline: 1px solid $primaryColor;
    }
    option {
      width: 100%;
      background-color: $primaryColorExtraLight;
      padding: calc(0.5 * #{$unit});
      // font-size: 0.875rem;
      color: $textDark;
      text-transform: capitalize;
      line-height: 3rem;
      &:checked,
      :focus {
        background-color: $primaryColor;
        color: $textLight;
      }
    }
  }
}
