@import '../../../styles/variables.module.scss';
.hero-section {
  position: relative;
  height: var(--doc-height);
  width: 100%;
  min-height: 640px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: $unit;
  padding-right: $unit;
  @media (min-width: $xxl) {
    padding: 0px;
  }
  .hero-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    gap: calc(1.5 * #{$unit});
    @media (min-width: $xxl) {
      max-width: 480px;
    }
    .btn-wrapper {
      width: 100%;
      max-width: 240px;
      @media (min-width: $lg) {
        max-width: 320px;
      }
    }
    .hero-body-message {
      display: flex;
      flex-direction: column;
      gap: $unit;
      h1,
      p {
        position: relative;
        color: $textLight;
        text-align: center;
      }
    }
  }
}
